<template>
  <div class="content">
    <el-row class="bgW">

      <el-col :span="2" style="text-align: right;float:right;">
        <el-button type="primary" icon="el-icon-search" size="mini" @click="add()">添加</el-button>
      </el-col>
    </el-row>
    <div class="accountCon">
      <!--列表-->
      <el-row>
        <el-col :span="24">
          <el-table
            :data="list"
            size="small"
            border
            v-loading="loading"
            :cell-style="$style.cellStyle"
            :header-cell-style="$style.rowClass"
          >
           <ElTableColumn label="ID" width="300" prop="id"  fixed="left"/>
            <ElTableColumn label="积分" prop="num" width="300"  fixed="left"/>
            <ElTableColumn label="备注" prop="message" width="300" fixed="left"/>
            <ElTableColumn label="状态" prop="status" width="300" fixed="left"/>
            <ElTableColumn label="操作" fixed="right">
              <template slot-scope="{ row }">
                <span class="theme" @click="change(row)">修改</span>
                <el-divider direction="vertical"></el-divider>
                <span class="theme" v-if="row.status == '启用'" @click="changeStatus(row.id)">禁用</span>
                <span class="theme" v-if="row.status == '禁用'" @click="changeStatus(row.id)">启用</span>
              </template>
            </ElTableColumn>
          </el-table>
          <pagination
            class="page tc mt10"
            :total="page.total"
            :page.sync="page.page"
            :limit.sync="page.limit"
            @pagination="getList"
          />
        </el-col>
      </el-row>
    </div>
    <el-dialog
      title="收货地址"
      width="30%"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      >
     <el-form :model="form">
        <el-form-item label="分数" >
          <el-input v-model="form.num" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="备注" >
          <el-input v-model="form.message" autocomplete="off"></el-input>
        </el-form-item>
<!--        <el-form-item label="活动区域" :label-width="formLabelWidth">
          <el-select v-model="form.region" placeholder="请选择活动区域">
            <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select>
        </el-form-item> -->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import { mapGetters } from "vuex";
import { addPointOrigin,pointOriginList,updatePointOrigin,changeOriginStatus } from "@/api/client";
import pagination from "@/components/Pagination";

class Page {
  total = 0;
  page = 1;
  limit = 10;
}
class Form{
  message = "";
  num = 0;
  id = 0;
}
export default {
  name: "PointOriginList",
  components: {
    pagination
  },
  data() {
    return {
      page: new Page(),
      form: new Form(),
      list:[],
      loading:false,
      dialogFormVisible:false,
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList(option){
      this.loading = true;
      pointOriginList({ ...this.page}).then(
        res => {
          this.list = res.data;
          this.page.total = res.total;
          this.loading = false;
        }
      );
    },
    change(row) {
      this.form.message = row.message;
      this.form.num = row.num;
      this.form.id = row.id;
      this.dialogFormVisible=true;
    },
    add(){
      this.form = new Form();
      this.dialogFormVisible=true;
    },
    changeStatus(id){
      changeOriginStatus({id:id}).then(
          res=>{
            if(res.code == 200)
            {
               this.$message({
                 message: res.msg,
                 type: 'success'
               });
            }else{
              this.$message({
                message: res.msg,
                type: 'warning'
              });
            }
            this.getList();
          }
      );
    },
    submitForm()
    {
      console.log(this.form);
      if(this.form.id == 0)
      {
        addPointOrigin({...this.form}).then(
          res=>{
            if(res.code == 200)
            {
               this.$message({
                 message: '添加成功',
                 type: 'success'
               });
            }else{
              this.$message({
                message: '添加失败',
                type: 'warning'
              });
            }
            this.dialogFormVisible=false;
            this.getList();
          }
        );
      }else{
        updatePointOrigin({...this.form}).then(
          res=>{
            if(res.code == 200)
            {
               this.$message({
                 message: '修改成功',
                 type: 'success'
               });
            }else{
              this.$message({
                message: '修改失败',
                type: 'warning'
              });
            }
            this.dialogFormVisible=false;
            this.getList();
          }
        );
      }
    }

  }
};
</script>
<style>

</style>

